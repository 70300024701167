import { useEffect, useState } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import Layout from 'manage-tritag/components/layout'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import {
  Association,
  QueryParams,
  useLazyGetAssociationsQuery,
} from 'manage-tritag/services/api/endpoints/associations'
import Spinner from 'manage-tritag/components/loading/spinner'
import { navigate } from 'gatsby'

interface HeadCell {
  disablePadding: boolean
  id: keyof Association
  label: string
  numeric: boolean
}

const AssociationPage = () => {
  const { user } = useAuth0()
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
  })
  const [trigger, result] = useLazyGetAssociationsQuery()
  const [isLoading, setIsLoading] = useState(true)
  const { isLoading: dataLoading, data: associations } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  useEffect(() => {
    if (user) {
      if (user['https://tritagrugby.com/user_metadata']) {
        if (user['https://tritagrugby.com/user_metadata'].activeRole) {
          if (
            ![1, 2].includes(
              user['https://tritagrugby.com/user_metadata'].activeRole.type,
            )
          ) {
            navigate('/')
          } else {
            setIsLoading(false)
          }
        }
      }
    }
  }, [user])

  const headCells: HeadCell[] = [
    {
      id: 'ttr_id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'subMaster',
      numeric: false,
      disablePadding: false,
      label: 'Sub Master',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Location',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
  ]
  const bodyCells = ['ttr_id', 'name', 'subMaster', 'address', 'status']
  return (
    <Layout label1="Associations" url="/associations">
      {isLoading || dataLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        associations && (
          <>
            <EnhancedTableFilter params={params} setParams={setParams} />
            <EnhancedTable
              tableTitle="Associations"
              uniqueIdentifier="ttr_id"
              rows={associations.map((association, index) => ({
                ...association,
                id: index,
                address: association.address.city,
                subMaster: association.subMaster
                  ? association.subMaster.name
                  : 'None',
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(AssociationPage)
